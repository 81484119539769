<template>
                <div class = "ng-star-inserted">
                    <div class = "clearfix">
                        <div class = "box-orderinfo"
                             style = "margin-bottom: 20px;">
                            <dl>
                                <dt>
                                    <div class = "item-text">订单号：PO131491631384596</div>
                                    <div class = "item-status">待收货</div>
                                    <div class = "item-status self-tag ng-star-inserted">自营</div>
                                </dt>
                                <dd>
                                    <ul>
                                        <li>订单类型：<span>自营订单</span></li>
                                        <li>外部订单号：<span>NOR202204245407024</span></li>
                                    </ul>
                                    <ul>
                                        <li>下单方式：<span>开放平台</span></li>
                                        <li>下单渠道：<span>????</span></li>
                                    </ul>
                                    <ul>
                                        <li>付款方式：<span>预付款支付</span></li>
                                        <li>配送方式：<span>普通物流</span></li>
                                    </ul>
                                </dd>
                            </dl>
                        </div>
                    </div>
                    <div>
                        <div class = "mb-lg ant-card">
                            <div class = "ant-card-head ng-star-inserted">
                                <div class = "ant-card-head-wrapper">
                                    <div class = "ant-card-head-title ng-star-inserted">流程进度</div>
                                </div>
                            </div>
                            <div class = "ant-card-body">
                                <div class = "ng-star-inserted">
                                    <div
                                        class = "ant-steps ant-steps-horizontal ant-steps-label-horizontal ant-steps-label-vertical ant-steps-dot">
                                        <div class = "ant-steps-item ng-star-inserted ant-steps-item-finish">
                                            <div class = "ant-steps-item-container" tabindex = "0">
                                                <div class = "ant-steps-item-tail ng-star-inserted"></div>
                                                <div class = "ant-steps-item-icon"><span
                                                    class = "ant-steps-icon ng-star-inserted"><span
                                                    class = "ant-steps-icon-dot ng-star-inserted"></span></span></div>
                                                <div class = "ant-steps-item-content">
                                                    <div class = "ant-steps-item-title">买家已下单</div>
                                                    <div class = "ant-steps-item-description">
                                                        <div class = "desc ng-star-inserted">
                                                            <div>2022-04-20</div>
                                                            <div>09:02:48</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class = "ant-steps-item ng-star-inserted ant-steps-item-finish">
                                            <div class = "ant-steps-item-container" tabindex = "0">
                                                <div class = "ant-steps-item-tail ng-star-inserted"></div>
                                                <div class = "ant-steps-item-icon"><span
                                                    class = "ant-steps-icon ng-star-inserted"><span
                                                    class = "ant-steps-icon-dot ng-star-inserted"></span></span></div>
                                                <div class = "ant-steps-item-content">
                                                    <div class = "ant-steps-item-title">付款成功</div>
                                                    <div class = "ant-steps-item-description">
                                                        <div class = "desc ng-star-inserted">
                                                            <div>2022-04-20</div>
                                                            <div>09:02:49</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class = "ant-steps-item ng-star-inserted ant-steps-item-finish">
                                            <div class = "ant-steps-item-container" tabindex = "0">
                                                <div class = "ant-steps-item-icon"><span
                                                    class = "ant-steps-icon ng-star-inserted"><span
                                                    class = "ant-steps-icon-dot ng-star-inserted"></span></span></div>
                                                <div class = "ant-steps-item-content">
                                                    <div class = "ant-steps-item-title">商品出库</div>
                                                    <div class = "ant-steps-item-description">
                                                        <div class = "desc ng-star-inserted">
                                                            <div>2022-05-05</div>
                                                            <div>19:07:25</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class = "steps-content ng-star-inserted"></div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div
                            nztitle = "卖家信息"
                            class = "mb-lg ant-card">
                            <div
                                class = "ant-card-head ng-star-inserted">
                                <div class = "ant-card-head-wrapper">
                                    <div
                                        class = "ant-card-head-title ng-star-inserted">卖家信息
                                    </div>
                                </div>
                            </div>
                            <div class = "ant-card-body">
                                <div
                                    class = "table-wrap ng-star-inserted">
                                    <div nzshowpagination = "false" class = "ant-table-wrapper">
                                        <div
                                            class = "ant-spin-nested-loading">
                                            <div class = "ant-spin-container ng-star-inserted">
                                                <div
                                                    class = "ant-table">
                                                    <div class = "ant-table-container ng-star-inserted">
                                                        <div
                                                            class = "ant-table-content">
                                                            <table nz-table-content = "" style = "table-layout: auto;">
                                                                <col
                                                                    class = "ng-star-inserted">
                                                                <col class = "ng-star-inserted">
                                                                <col class = "ng-star-inserted">
                                                                <thead
                                                                    class = "ant-table-thead ng-star-inserted">
                                                                <tr class = "ant-table-row ng-star-inserted">
                                                                    <th
                                                                        class = "ant-table-cell">收件人
                                                                    </th>
                                                                    <th class = "ant-table-cell">联系电话</th>
                                                                    <th
                                                                        class = "ant-table-cell">收货地址
                                                                    </th>
                                                                </tr>
                                                                </thead>
                                                                <tbody class = "ant-table-tbody ng-star-inserted">
                                                                <tr
                                                                    class = "ant-table-row">
                                                                    <td class = "ant-table-cell">潘潘</td>
                                                                    <td
                                                                        class = "ant-table-cell">15800019709
                                                                    </td>
                                                                    <td
                                                                        class = "ant-table-cell">广东省 广州市 黄埔区
                                                                        地址：广州市黄埔区联和街道广汕公路黄陂段146号龙光峰景华庭八栋A座601
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class = "box-logistics-wrap">
                        <div nztitle = "物流信息" class = "mb-lg ant-card">
                            <div
                                class = "ant-card-head ng-star-inserted">
                                <div class = "ant-card-head-wrapper">
                                    <div
                                        class = "ant-card-head-title ng-star-inserted">物流信息
                                    </div>
                                </div>
                            </div>
                            <div class = "ant-card-body">
                                <div
                                    class = "box-logistics ng-star-inserted">
                                    <div class = "cell-info ng-star-inserted">
                                        <div>物流单号：432521226453592
                                            <button
                                                nz-button = "" nzsize = "small" nztype = "primary"
                                                class = "ant-btn ant-btn-primary ant-btn-sm"><span
                                                class = "ng-star-inserted">复制</span></button>
                                        </div>
                                        <div>物流公司：韵达快递</div>
                                        <div>发货时间：2022-04-25 17:47:10</div>
                                    </div>
                                    <div
                                        class = "cell-log ng-star-inserted">
                                        <div style = "display: flex;">
                                            <div>
                                                <div nzmode = "default"
                                                     class = "ng-tns-c228-11 ng-trigger ng-trigger-fadeMotion ant-tag ant-tag-checkable-checked">
                                                    最新
                                                </div>
                                            </div>
                                            <div><p>时间：2022-04-26 21:16:32</p>
                                                <p
                                                    style = "margin: auto;">【代收点】您的快件已签收，签收人在
                                                    广州龙光峰景华庭6B102号店(已签收签收人凭取货码签收)领取，投诉电话:020-23393006 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <span
                                                        style = "color: #de0000; cursor: pointer;">详情</span></p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        style = "overflow: hidden;">
                        <div nztitle = "商品信息" class = "mb-lg ant-card">
                            <div
                                class = "ant-card-head ng-star-inserted">
                                <div class = "ant-card-head-wrapper">
                                    <div
                                        class = "ant-card-head-title ng-star-inserted">商品信息
                                    </div>
                                </div>
                            </div>
                            <div class = "ant-card-body">
                                <div
                                    class = "ant-table-wrapper ng-star-inserted">
                                    <div class = "ant-spin-nested-loading">
                                        <div
                                            class = "ant-spin-container ng-star-inserted">
                                            <div class = "ant-table">
                                                <div
                                                    class = "ant-table-container ng-star-inserted">
                                                    <div class = "ant-table-content">
                                                        <table
                                                            nz-table-content = "" style = "table-layout: auto;">
                                                            <col class = "ng-star-inserted">
                                                            <col
                                                                class = "ng-star-inserted">
                                                            <col class = "ng-star-inserted">
                                                            <col class = "ng-star-inserted">
                                                            <col
                                                                class = "ng-star-inserted">
                                                            <thead class = "ant-table-thead ng-star-inserted">
                                                            <tr
                                                                class = "ant-table-row ng-star-inserted">
                                                                <th width = "400px" class = "ant-table-cell">商品</th>
                                                                <th
                                                                    nzalign = "center" class = "ant-table-cell"
                                                                    style = "text-align: center;">状态
                                                                </th>
                                                                <th
                                                                    nzalign = "center"
                                                                    class = "ant-table-cell"
                                                                    style = "text-align: center;">单价
                                                                </th>
                                                                <th
                                                                    nzalign = "center" class = "ant-table-cell"
                                                                    style = "text-align: center;">数量
                                                                </th>
                                                                <th
                                                                    nzalign = "center"
                                                                    class = "ant-table-cell"
                                                                    style = "text-align: center;">小计(元)
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody
                                                                class = "ant-table-tbody ng-star-inserted">
                                                            <tr class = "ant-table-row ng-star-inserted">
                                                                <td
                                                                    class = "ant-table-cell">
                                                                    <div class = "service-info">
                                                                        <div style = "display: flex; flex: 1;"><img
                                                                            alt = ""
                                                                            src = "https://scce-cos-prd.obs.cn-south-1.myhuaweicloud.com:443/a8ff2ec8ad9b47e78430e93d28d21e6e.jpg">
                                                                            <dl
                                                                                style = "margin-bottom: 0;">
                                                                                <dt>透真玻尿酸多效保湿蚕丝面膜</dt>
                                                                                <dd style = "margin-bottom: 0;"
                                                                                    class = "ng-star-inserted">规格：面膜20片
                                                                                </dd>
                                                                            </dl>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    nzalign = "center" class = "ant-table-cell"
                                                                    style = "text-align: center;">
                                                                    <div
                                                                        class = "service-info">
                                                                        <div><p
                                                                            style = "margin: 0;"><label
                                                                            class = "ng-star-inserted">已发货</label></p>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    nzalign = "center" class = "ant-table-cell"
                                                                    style = "text-align: center;">￥41.00
                                                                </td>
                                                                <td
                                                                    nzalign = "center" class = "ant-table-cell"
                                                                    style = "text-align: center;">2
                                                                </td>
                                                                <td
                                                                    nzalign = "center"
                                                                    class = "ant-table-cell"
                                                                    style = "text-align: center;">￥82.00
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class = "order-total-info ng-star-inserted">
                                    <ul>
                                        <li>商品总额：￥82.00</li>
                                        <li>运费：￥0.00</li>
                                        <li>实付款：<span
                                            class = "text-red">￥82.00</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
</template>

<script>

    export default {
        name: "order-detail",
        components: {
        },
    }
</script>

<style lang = "scss" scoped>
    .body {
        background: #fbfbfb;
    }

    .page-wrap {
        width: 1200px;
        margin: 0 auto;
    }

    .self-tag {
        margin-left: 10px;
    }

    dt {
        font-weight: 500;
    }

    .mb-lg {
        margin-bottom: 24px !important;
    }

    .box-orderinfo {
        float: left;
        width: 100%;
        padding: 22px 30px 30px;
        box-sizing: border-box;
        background-color: #fff;

        dl, dt, dd, ul, li {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        dl {
            dt {
                display: flex;
                align-items: center;
                padding-bottom: 6px;

                .item-text {
                    flex: 1;
                    font-size: 20px;
                    line-height: 32px;
                    color: #000;
                    font-weight: 500;
                    color: rgba(0, 0, 0, .85);
                }

                .item-status {
                    text-align: center;
                    width: 75px;
                    height: 32px;
                    line-height: 32px;
                    background-color: #de0000;
                    color: #fff;
                }
            }

            dd {
                font-size: 12px;

                ul {
                    display: flex;

                    li {
                        flex: 1;
                        line-height: 22px;
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(0, 0, 0, .85);

                        span {
                            color: rgba(0, 0, 0, .65);
                        }
                    }
                }
            }
        }

        .ant-card {
            transition: none;

            .ant-card-head-title {
                padding: 15px 0;
            }
        }

        .ant-steps-dot {
            .ant-steps-item-tail {
                top: 2px;
                width: 100%;
                margin: 0 0 0 70px;
                padding: 0;
            }
        }

        .ant-steps-dot.ant-steps-small {
            .ant-steps-item-tail {
                top: 2px;
                width: 100%;
                margin: 0 0 0 70px;
                padding: 0;
            }
        }
    }

    .ant-steps-label-vertical {
        .ant-steps-item {
            overflow: visible;
        }

        .ant-steps-item-icon {
            display: inline-block;
            margin-left: 42px;
        }

        .ant-steps-item-content {
            display: block;
            width: 116px;
            margin-top: 8px;
            text-align: center;
        }
    }

    .ant-steps-item {
        position: relative;
        display: inline-block;
        flex: 1;
        overflow: hidden;
        vertical-align: top;
    }

    .ant-steps-item:last-child {
        flex: none;
    }

    .ant-steps-dot .ant-steps-item-content, .ant-steps-dot.ant-steps-small .ant-steps-item-content {
        width: 140px;
    }

    .ant-card-head-title, .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        font-weight: 500;
        color: rgba(0, 0, 0, .85);
    }

    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
        color: rgba(0, 0, 0, .45);
    }

    .ant-steps-item-container {
        outline: none;
    }

    .ant-steps-item-tail {
        position: absolute;
        top: 12px;
        left: 0;
        width: 100%;
        padding: 0 10px;
    }

    .ant-steps-item-finish {
        .ant-steps-item-container {
            .ant-steps-item-tail {

            }

            .ant-steps-item-tail:after {
                background-color: #1890ff;
            }
        }
    }

    /*.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot, .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail:after {*/
    /*    background: #1890ff;*/
    /*}*/
    .ant-steps-item-content, .ant-steps-item-icon {
        display: inline-block;
        vertical-align: top;
    }

    .ant-steps-item-icon {
        width: 32px;
        height: 32px;
        margin: 0 8px 0 0;
        font-size: 16px;
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
        line-height: 32px;
        text-align: center;
        border: 1px solid rgba(0, 0, 0, .25);
        border-radius: 32px;
        transition: background-color .3s, border-color .3s;
    }


    .ant-steps-dot .ant-steps-item-icon, .ant-steps-dot.ant-steps-small .ant-steps-item-icon {
        width: 8px;
        height: 8px;
        margin-left: 67px;
        padding-right: 0;
        line-height: 8px;
        background: transparent;
        border: 0;
    }

    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
        color: #de0000;
    }

    .ant-steps-item-icon > .ant-steps-icon {
        position: relative;
        top: -1px;
        color: #de0000;
        line-height: 1;
    }

    .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot, .ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
        position: relative;
        float: left;
        width: 100%;
        height: 100%;
        border-radius: 100px;
        transition: all .3s;
    }

    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
        background: #de0000;
    }

    .ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot, .ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
        left: 2px;
    }

    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot, .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail:after {
        background: #1890ff;
    }


    .ant-steps-dot .ant-steps-item-tail:after, .ant-steps-dot.ant-steps-small .ant-steps-item-tail:after {
        width: calc(100% - 20px);
        height: 3px;
        margin-left: 12px;
    }

    .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot:after, .ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot:after {
        position: absolute;
        top: -12px;
        left: -26px;
        width: 60px;
        height: 32px;
        background: rgba(0, 0, 0, .001);
        content: "";
    }

    .ant-steps-item-content, .ant-steps-item-icon {
        display: inline-block;
        vertical-align: top;
    }


    .ant-steps-dot .ant-steps-item-content, .ant-steps-dot.ant-steps-small .ant-steps-item-content {
        width: 140px;
    }

    .ant-steps-label-vertical .ant-steps-item-title {
        padding-right: 0;
        padding-left: 0;
    }

    .ant-steps-dot .ant-steps-item-title, .ant-steps-dot.ant-steps-small .ant-steps-item-title {
        line-height: 1.5715;
    }

    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        color: rgba(0, 0, 0, .65);
    }

    .ant-card-head-title, .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        font-weight: 500;
        color: rgba(0, 0, 0, .85);
    }

    .ant-steps-item-title {
        position: relative;
        display: inline-block;
        padding-right: 16px;
        color: rgba(0, 0, 0, .65);
        font-size: 16px;
        line-height: 32px;
    }

    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
        color: rgba(0, 0, 0, .45);
    }

    .ant-steps-item-description, .ant-steps-item-subtitle {
        color: rgba(0, 0, 0, .45);
        font-size: 14px;
    }

    .ant-steps-label-vertical .ant-steps-item-tail {
        margin-left: 58px;
        padding: 3.5px 24px;
    }

    .ant-steps-dot .ant-steps-item-tail, .ant-steps-dot.ant-steps-small .ant-steps-item-tail {
        top: 2px;
        width: 100%;
        margin: 0 0 0 70px;
        padding: 0;
    }

    .ant-steps-item-tail:after {
        display: inline-block;
        width: 100%;
        height: 1px;
        background: #f0f0f0;
        border-radius: 1px;
        transition: background .3s;
        content: "";
    }

    .ant-spin-nested-loading {
        position: relative;

        .ant-spin-container {
            position: relative;
            transition: opacity .3s;
        }
    }

    .ant-table {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: rgba(0, 0, 0, .65);
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: "tnum";
        position: relative;
        z-index: 0;
        clear: both;
        background: #fff;
        border-radius: 2px;

        .ant-table-container, .ant-table-container table > thead > tr:first-child th:first-child {
            border-top-left-radius: 2px;
        }

        table {
            width: 100%;
            text-align: left;
            border-radius: 2px 2px 0 0;
            border-collapse: separate;
            border-spacing: 0;
        }

        .ant-table-thead > tr > th {
            color: rgba(0, 0, 0, .85);
            font-weight: 500;
            text-align: left;
            background: #fafafa;
            border-bottom: 1px solid #f0f0f0;
            transition: background .3s ease;
        }

        .ant-table-tbody > tr > td, .ant-table-thead > tr > th, .ant-table tfoot > tr > td, .ant-table tfoot > tr > th {
            position: relative;
            padding: 16px;
            overflow-wrap: break-word;
        }

        .ant-table-container:after, .ant-table-container:before {
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 1;
            width: 30px;
            transition: box-shadow .3s;
            content: "";
            pointer-events: none;
        }

        .service-info {
            display: flex;
            align-items: center;
            justify-content: center;

            .col-img, img {
                display: block;
                width: 60px;
                height: 60px;
            }
        }
    }

    .order-total-info {
        text-align: right;
        padding: 10px 0;
        font-size: 14px;
        line-height: 24px;

        ul {
            list-style: none;
        }

        .text-red {
            color: #de0000;
        }
    }

    .text-red {
        color: #f5222d !important;
    }

    .box-logistics-wrap {
        .ant-card-body {
            padding: 10px 20px;
        }

        .box-logistics {
            width: 100%;
            height: 290px;
            background-color: #fff;
            padding: 0;
            font-size: 12px;
            overflow: auto;

            .cell-info {
                display: flex;
                padding: 10px 33px;
                align-items: center;
                justify-content: space-between;
                font-weight: 500;
                color: rgba(0, 0, 0, .85);
                font-size: 14px;
                background: #fafafa;

                .ant-btn-primary {
                    height: 22px;
                    line-height: 21px;
                    background: #fff;
                    font-size: 12px;
                    color: #666;
                    border: 1px solid #c1c1c1;
                    border-radius: 4px;
                }
            }

            .cell-log {
                max-height: 220px;
                background-color: #f7f7f7;
                padding: 20px 22px;

                .ant-tag-checkable-checked {
                    background-color: #de0000;
                }

                .ant-tag-checkable-checked, .ant-tag-checkable:active {
                    color: #fff;
                }
            }
        }
    }

    .ant-tag {
        box-sizing: border-box;
        color: rgba(0, 0, 0, .65);
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: "tnum";
        display: inline-block;
        height: auto;
        margin: 0 8px 0 0;
        padding: 0 7px;
        font-size: 12px;
        line-height: 20px;
        white-space: nowrap;
        background: #fafafa;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        cursor: default;
        opacity: 1;
        transition: all .3s cubic-bezier(.78, .14, .15, .86);
    }

    .ant-btn-primary {
        color: #fff;
        background-color: #de0000;
        border-color: #de0000;
        text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
        box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    }

    .ant-btn-sm {
        height: 24px;
        padding: 0 7px;
        font-size: 14px;
        border-radius: 2px;
        line-height: 22px;
    }

    .ant-table-tbody > tr > td {
        border-bottom: 1px solid #f0f0f0;
        transition: background .3s;
    }
</style>